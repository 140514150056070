<script setup lang="ts">
const { tagValue, type, href } = defineProps<{
  href: string;
  tagValue: string;
  type: string;
}>();

async function onClick(e: Event) {
  e.preventDefault();
  await navigateTo(href);
}
</script>
<template>
  <button class="btn-tag" :class="type" @click="onClick">
    {{ tagValue }}
  </button>
</template>
<style lang="scss" scoped>
.btn-tag {
  padding: 6px 10px;
  border-radius: 6px;
  border: 1px solid $grey-medium;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 8px;
  max-height: 25px;
  border: 1px solid transparent;
  margin-right: 10px;
  text-decoration: none;
  &:last-child {
    margin-right: 0;
  }
  &.primary {
    background: $grey-medium;
    color: $white;
  }
  &.red {
    background-color: $primary-color;
    color: $white;
  }
  &.secondary {
    background: $grey;
    color: $grey-medium;
    border-color: $grey-medium;
  }
  &:hover {
    transition: all ease-in-out 0.3s;
    &.primary {
      transition: all ease-in-out 0.3s;
      background: $grey;
      color: $grey-light;
    }
    &.secondary {
      border-color: $white;
      color: $white;
    }
  }
}
</style>
